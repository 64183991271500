import React, { Component } from 'react';
import '../App.css';
import styled from 'styled-components';

class SoundcloudPlayer extends Component {

    render() {
        return (
            <Center>
                <ActWrapper>
                    <Fixed>
                        <div>
                            <PlayerGradient>
                                <img src={require('../images/gradient.png')} alt='Player Gradient' />
                            </PlayerGradient>
                            <iframe title='Rites Of Passage' width="98%" height="20" scrolling="no" frameBorder="yes" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/704257138&color=999999&inverse=false&auto_play=false&show_user=true"></iframe>
                        </div>
                    </Fixed>
                </ActWrapper>
            </Center>
        )
    }
}

export { SoundcloudPlayer }

const Fixed = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    opacity: 1;
    margin: 0 auto;
    z-index: 100;
    background-color: #e5e5e5;
    height: 40px;

    iframe {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        padding: 10px 5px;
        border: none;
    }
`
const Center = styled.div`
    margin: 0 auto;
`
const ActWrapper = styled.div`
    width: 100vw;
    margin-top: 25px;
    margin: 0 auto 25px auto;
    justify-content: center;
    max-width: 1200px;
`
const PlayerGradient = styled.div`
    width: 100vw;
    transform: translateY(5px);
    pointer-events: none;
    position: relative;
    bottom: 55px;
`