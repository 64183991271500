import React, { Component } from "react";
import "../App.css";
import styled from "styled-components";

class Socials extends Component {
	render() {
		return (
			<SocialsWrapper>
				<a href="https://www.facebook.com/sol1tarydancer" target="_blank" rel="noopener noreferrer">
					<img alt="facebook" src="https://res.cloudinary.com/wy/image/upload/w_23/v1567291931/sd/fb.png"></img>
				</a>
				<a href="https://www.instagram.com/solitarydancer_" target="_blank" rel="noopener noreferrer">
					<img
						alt="instagram"
						src="https://res.cloudinary.com/wy/image/upload/w_18/v1567284976/sd/glyph-logo_May2016.png"
					></img>
				</a>
				<a href="https://soundcloud.com/solitary-dancer" target="_blank" rel="noopener noreferrer">
					<img
						alt="soundcloud"
						src="https://res.cloudinary.com/wy/image/upload/w_28/v1567291931/sd/soundcloud.png"
					></img>
				</a>
				<a
					href="https://open.spotify.com/artist/676jXFZDJC6IqruhBrZiIr?si=On212qorQNmpFkE7aEkwiQ"
					target="_blank"
					rel="noopener noreferrer"
				>
					<img
						alt="spotify"
						src="https://res.cloudinary.com/wy/image/upload/w_20/v1567285208/sd/Spotify_Icon_RGB_Black.png"
					></img>
				</a>
				<a href="https://privatepossessions.bandcamp.com/releases" target="_blank" rel="noopener noreferrer">
					<img
						alt="bandcamp"
						src="https://res.cloudinary.com/wy/image/upload/w_20/v1572904452/sd/bandcamp-button-bc-circle-black-512.png"
					></img>
				</a>
				<a href="mailto:info@solitarydancer.net" target="_blank" rel="noopener noreferrer">
					<img alt="email" src="https://res.cloudinary.com/wy/image/upload/w_20/v1572908175/sd/mail.png"></img>
				</a>
			</SocialsWrapper>
		);
	}
}

export default Socials;

const SocialsWrapper = styled.div`
	right: 0;
	left: 0;
	position: relative;
	color: black;
	margin: 0 auto;
	padding: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-bottom: 75px;
	padding-top: 10px;

	img {
		max-height: 20px;
		cursor: pointer;
		opacity: 0.5;
		margin-left: 7.5px;
	}

	img:hover {
		opacity: 1;
	}

	@media (min-width: 768px) {
		right: 25px;
		top: 25px;
		left: initial;
		position: fixed;
		display: block;
		padding-bottom: 0;
		padding-top: 0;
		margin: 0;
		display: flex;
		justify-content: center;
		align-items: center;
	}
`;
