import React, { Component } from "react";
import "../App.css";
import styled, { keyframes } from "styled-components";
import { SoundcloudPlayer } from "./SoundcloudPlayer";
import Socials from "../components/Socials";

let ritesOfPassageCovers = [
	"https://res.cloudinary.com/wy/image/upload/w_2000,f_auto,dpr_auto/v1573052063/sd/ROP_1.jpg",
	"https://res.cloudinary.com/wy/image/upload/w_2000,f_auto,dpr_auto/v1573055459/sd/ROP_2.jpg",
	"https://res.cloudinary.com/wy/image/upload/w_2000,f_auto,dpr_auto/v1573055458/sd/ROP_3.jpg",
];
class Intro extends Component {
	constructor() {
		super();
		this.state = {
			artwork: 0,
		};
	}

	componentDidMount() {
		// updates rites of passage artwork covers
		setTimeout(() => setInterval(this.updateCoverart, 3500), 2500);
	}

	updateCoverart = () => {
		if (this.state.artwork >= 2) {
			this.setState({ artwork: 0 });
		} else this.setState({ artwork: this.state.artwork + 1 });
	};

	render() {
		return (
			<SiteContainer>
				<TitleWrapper>
					<IntroWrapper>
						<About id="about">
							<EmbedContainer padBottom>
								<iframe
									title="Y3 Presentation | Spring / Summer 2025"
									src="https://www.youtube.com/embed/6lKQpFWAdpo"
									frameborder="0"
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
									allowfullscreen
								></iframe>
							</EmbedContainer>
							<EmbedContainer padBottom>
								<iframe
									title="ZOO by Will Niava"
									src="https://player.vimeo.com/video/479486845"
									frameborder="0"
									allow="autoplay; fullscreen"
									allowfullscreen
								></iframe>
								<br />
								<br />
								<br />
								<br />
							</EmbedContainer>
							<EmbedContainer>
								<iframe
									title="Solitary Dancer - U.N.I.T.Y."
									src="https://www.youtube.com/embed/aCn4ny56RNU"
									frameborder="0"
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
									allowfullscreen
								></iframe>
							</EmbedContainer>
							<a
								href="https://privatepossessions.bandcamp.com/album/reveries-of-a-solitary-dancer"
								target="_blank"
								rel="noopener noreferrer"
							>
								<img
									alt="Reveries Of A Solitary Dancer"
									src="https://res.cloudinary.com/wy/image/upload/q_auto,f_auto,dpr_auto/v1601425898/sd/reveries_of_a_solitary_dancer.png"
								></img>
							</a>
							<a
								href="https://privatepossessions.bandcamp.com/album/postlude"
								target="_blank"
								rel="noopener noreferrer"
							>
								<img
									alt="Postlude"
									src="https://res.cloudinary.com/wy/image/upload/v1591626184/sd/sd_postlude.png"
								></img>
							</a>
							<a
								href="https://privatepossessions.bandcamp.com/album/rites-of-passage"
								target="_blank"
								rel="noopener noreferrer"
							>
								<img
									loading="lazy"
									id="rop-cover"
									alt="Rites of Passage"
									src={ritesOfPassageCovers[this.state.artwork]}
								></img>
							</a>
							<a
								href="https://optimomusic.bandcamp.com/album/in-the-name-of-the-mother"
								target="_blank"
								rel="noopener noreferrer"
							>
								<img
									loading="lazy"
									alt="In The Name Of The Mother"
									src="https://res.cloudinary.com/wy/image/upload/f_auto,dpr_auto/v1573052615/sd/EP_4_lr.jpg"
								></img>
							</a>
							<a
								href="https://solitarydancer.bandcamp.com/album/transmitting-to-heaven"
								target="_blank"
								rel="noopener noreferrer"
							>
								<img
									loading="lazy"
									alt="Transmitting To Heaven"
									src="https://res.cloudinary.com/wy/image/upload/q_auto,f_auto,dpr_auto/v1573052667/sd/EP_3.png"
								></img>
							</a>
							<a href="https://solitary-dancer.bandcamp.com/album/dualism" target="_blank" rel="noopener noreferrer">
								<img
									loading="lazy"
									alt="Dualism"
									src="https://res.cloudinary.com/wy/image/upload/w_2000,q_auto,f_auto,dpr_auto/v1573052797/sd/EP_2.jpg"
								></img>
							</a>
							<a
								href="https://solitarydancer.bandcamp.com/album/solitary-dancer"
								target="_blank"
								rel="noopener noreferrer"
							>
								<img
									loading="lazy"
									alt="Solitary Dancer"
									src="https://res.cloudinary.com/wy/image/upload/q_auto,f_auto,dpr_auto/v1573052799/sd/EP_1.jpg"
								></img>
							</a>
						</About>
					</IntroWrapper>
				</TitleWrapper>
				<SoundcloudPlayer />
				<Socials />
			</SiteContainer>
		);
	}
}

export default Intro;

const FadeIn = keyframes`
    from { opacity: 0; visibility: hidden;}
    to { opacity: 1; visibility: visible;}
`;
const SiteContainer = styled.div`
	visibility: hidden;
	animation: ${FadeIn} 2.5s ease 0.5s;
	animation-fill-mode: forwards;
`;
const TitleWrapper = styled.div`
	padding-top: 15px;
	margin-left: auto;
	margin-right: auto;
	/* animation: ${FadeIn} 2.5s ease .5s;
    animation-fill-mode: forwards; */

	@media (min-width: 768px) {
		padding-top: 25px;
	}
`;
const IntroWrapper = styled.div`
	display: inline-block;
	width: 95vw;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;

	img {
		width: 95vw;
		height: auto;
		margin-top: 25px;
	}

	@media (min-width: 768px) {
		padding-bottom: 65px;

		img {
			width: 50vw;
			max-width: 1000px;
			height: auto;
			margin-top: 50px;
		}
	}
`;
const About = styled.div`
	a {
		color: black;
		text-decoration: none;
		&:hover {
			cursor: pointer !important;
		}
		margin: 0;
		padding: 0;
		height: 0;
		line-height: 0;
		font-size: 0;
	}

	video {
		width: 95vw;
		height: auto;
	}

	@media (min-width: 768px) {
		width: 50vw;
		max-width: 1000px;

		video {
			width: 50vw;
			height: auto;
			max-width: 1000px;
		}
	}
`;
const EmbedContainer = styled.div`
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;
	max-width: 100%;
	margin-bottom: ${(props) => (props.padBottom ? "40px" : "initial")};

	iframe,
	object,
	embed {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
`;
